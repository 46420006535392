import 'intersection-observer'

$(document).ready(function () {
  window.addEventListener('scroll', function() {
    if ($(window).scrollTop() >= 1) {
      $(".navbar").addClass("compressed");
    } else {
      $(".navbar").removeClass("compressed");
    }
  }, {
    passive: true
  })

  const options = {
    rootMargin: '0px',
    threshold: [0,0.25,0.5, 0.52, 1]
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio < 0.51) {
        return
      }

      const active = document.querySelector('.active')

      active && active.classList.remove('active')

      document.querySelector('nav.navbar a[href^="#' + entry.target.id + '"]').classList.add('active')
    })
  }, options);

  const obeservedElements = Array.from(
    document.querySelectorAll('nav.navbar a[href^="#"]')
  )
  .map(element => element.href.split("#")[1])
  .map(id => document.getElementById(id))

  Array.from(obeservedElements)
  .filter(e => e)
  .forEach((element) => observer.observe(element))
});
