const protectEmails = (element) => {
  let content = ''
  content += element.dataset.first || ''
  content += '@'
  content += element.dataset.second || ''

  element.innerHTML = content
}

const protectElements = (element) => {
  switch (element.dataset.type) {
    case 'email':
      protectEmails(element)
      break;

    default:
      throw new Error('Element type not supported')
  }
}

Array.from(document.querySelectorAll('.js-protect'))
  .forEach(protectElements)
