import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

const addSmoothScroll = (element) => {
  element.addEventListener('click', function (e) {

    document
    .querySelector(this.hash || 'body')
    .scrollIntoView({
      behavior: 'smooth',
      block: "start",
      inline: "nearest"
    });

    e.preventDefault();
  })
}

Array.from(
  document.querySelectorAll('a[class*="smoothscroll"][href^="#"]')
)
.forEach(addSmoothScroll)
